*,::after,::before {
  font-family: 'Rubik', sans-serif;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
}

code {
  font-family: 'Rubik', sans-serif;
}
