.eqc-header {
  margin-bottom: 15px;
  .eqc-header-buttons {
    .ant-btn {
      margin-right: 5px;
      display: flex;
      align-items: center;
      svg {
        width: 25px;
        fill: @whiteColor;
      }
    }
  }
  &-title {
    align-items: center;
    h1 {
      margin-bottom: 0px;
    }
    svg {
      fill: @dq-colorPrimary;
      width: 35px;
      height: 35px;
      fill: @dq-colorPrimary;
      margin-right: 15px;
      margin-bottom: 2px;
    }
  }
}

.details-header {
  align-items: center;
  h2 {
    margin-bottom: 0px;
    svg {
      margin-right: 5px;
      width: 30px;
      height: 30px;
      fill: @dq-colorPrimary;
    }
  }
  .ant-btn {
    margin-right: 5px;
    display: flex;
    align-items: center;
    svg {
      width: 25px;
    }
  }
  .edit-button-eqc {
    svg {
      path {
        fill: @whiteColor;
      }
    }
  }
}

.details-header .export-button {
  &:hover {
    svg {
      fill: @dq-colorPrimary;
    }
  }
  svg {
    fill: @grey-10;
  }
}

.stage-list-card {
  .stage-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    padding: 16px;
  }
  .title {
    max-width: 45%;
  }
  &.project-stages.ant-card-bordered {
    border: 1px solid #e8e8e8;
    border-radius: 12px;
  }
  .eqc-stage-header-buttons {
    &.global-checklist-stage {
      @media screen and (min-width: 999px) {
        .ant-btn-round {
          padding: 7.9px 14px;
        }
      }
    }
    .import-button {
      background: @inactive-tag-background;
      svg {
        fill: @grey-10;
      }
      &:hover svg {
        fill: #ff784f;
      }
      &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        color: #ff784f;
        border-color: #ff784f;
        background: #e5e8ed;
      }
    }
    .add-button {
      background: @headerTextColor;
      border: none;
      color: white;
      &:focus,
      &:hover {
        background: #202a3fd7;
      }
      &[disabled] {
        color: white;
        background: rgba(166, 170, 178);
      }
    }
    .ant-btn {
      margin-right: 5px;
      display: flex;
      align-items: center;
      svg {
        width: 26px;
      }
    }
  }

  .ant-table-content {
    border: none;
  }

  .stage-row {
    .ant-table-cell {
      padding: 0;
    }
  }
}

.stage-item-card {
  &.project-checklist {
    .ant-card-body {
      padding: 0;
    }
    @media screen and (max-width: 999px) {
      &.ant-card-bordered {
        border: none;
      }
    }
  }
  .radio-div {
    padding: 2px 12px;
    border-left: 2px solid #cdd5e0;
    margin-left: 12px;
    height: 25px;
    border-radius: 2px;
  }
  .ant-radio-group {
    .active-radio {
      color: @dq-colorPrimary;
      font-weight: 500;
    }
  }
  .ant-radio-wrapper {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: @colorLabel;
  }
  .eqc-stage-header-buttons {
    .add-button {
      background: @headerTextColor;
      border: none;
      color: white;
      &:focus,
      &:hover {
        background: #202a3fd7;
      }
      &[disabled] {
        color: white;
        background: rgba(166, 170, 178);
      }
    }
    .ant-btn {
      margin-right: 5px;
      display: flex;
      align-items: center;
      svg {
        width: 26px;
      }
    }
  }
}

.stageitem-modal {
  .checklist-item-note-popup {
    display: flex;
    padding: 8px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    .ant-popover-arrow {
      .ant-popover-arrow-content {
        --antd-arrow-background-color: #101326;
      }
    }
    .ant-popover-inner {
      width: 300px;
      border-radius: 8px;
      background: #101326;
      box-shadow: 0px 25px 50px 0px rgba(81, 89, 131, 0.25);
    }

    .ant-popover-inner-content {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  .ant-divider-dashed {
    border-color: #cdd5e0;
    border-width: 2px 0 0;
  }
  .ant-divider-horizontal {
    margin: 15px 0;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #f5f5f5;
  }
  .ant-checkbox-inner {
    border-radius: 4px;
    width: 20px;
    height: 20px;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    background: @headerTextColor;
    border-color: @headerTextColor;
  }
  .value-input {
    margin: 10px 5px;
    height: 30px;
    .ant-form-item-control-input {
      width: 131px;
    }
  }
  .numeric-input {
    margin: 10px 5px;
    height: 30px;
  }
  h2 {
    margin: 0;
  }
  .note {
    color: @label-color;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-decoration-line: underline;
    width: fit-content;
    padding: 12px 0 16px;
    margin-right: 5px;
  }
}

.grey-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled) {
  background: @light-blue;
  border-color: @light-blue;
  &:hover,
  &:focus {
    color: @grey-10;
    border-color: @grey-10;
  }
}

.optionsInput {
  width: 310px;
}

.row-dragging {
  background: @light-blue;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-btn {
    background-color: transparent;
    padding: 0 8px;
    box-shadow: none;
    svg {
      fill: #fff;
    }
  }
}

.row-dragging td {
  padding: 0 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.eqc-module {
  .ant-table-thead > tr > th {
    color: @grey-10;
  }
  .ant-table-tbody > tr > td {
    color: @headerTextColor;
  }
}

.drag-icon {
  cursor: grabbing;
  color: #999;
}
@media screen and(max-width:576px) {
  .stage-item-card .radio-div {
    width: 100%;
    padding: 2px 6px;
    margin: 0px;
    height: 32px;
    display: flex;
    align-items: center;
    .ant-radio-wrapper {
      margin: 0px;
    }
  }
}
@media screen and( max-width: 999px) {
  .stage-list-card {
    &.ant-card-bordered {
      border: none;
      border-radius: 0;
      .ant-card-body {
        padding: 0;
        .header {
          padding: 16px 16px 0 16px;
        }
        h2 {
          margin-bottom: 0;
          font-size: 16px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .mobile-eqc-type-action-btn {
    button {
      display: block;
      margin-bottom: 10px;
    }
  }
  .eqc-type-scroll-wrapper {
    overflow-y: auto;
    height: calc(100vh - 291px);
    min-height: 400px;
  }
  #project-stage-item-module {
    border: none;
    .ant-card-body {
      padding: 12px;
      .stage-card {
        border: 1px solid @grey-4;
        padding: 16px;
      }
    }
    .stage-item-scroll-wrapper {
      overflow-y: auto;
      height: calc(100vh - 200px);
      margin-top: 10px;
      min-height: 400px;
      .infinite-data-container {
        div {
          opacity: unset !important;
        }
      }
      .stage-item-action-btn {
        bottom: 10px;
        right: 2px;
        .edit-button {
          button {
            background: none;
            margin-right: 5px;
          }
        }
      }
    }
    &.skeleton-card {
      border: 1px solid @border-color;
      padding: 16px;
    }
  }
  #stage-item-modal {
    .ant-modal {
      height: 100vh;
      top: 0;
      margin: 0;
      max-width: 100vw;
    }
    .ant-modal-content {
      border-radius: 0;
      height: 100vh;
      padding: 0;
      .ant-modal-close-x {
        font-size: 22px;
      }
      .ant-modal-header {
        border-bottom: none;
        padding: 16px;
        .ant-modal-title {
          padding-right: 23px;
          font-size: 20px;
        }
      }
      .ant-modal-body {
        padding: 0 16px 16px;
      }
      .ant-card.ant-card-bordered {
        border: none;
        h2 {
          margin-bottom: 0;
          color: @colorLabel;
          font-size: 14px;
          display: flex;
          align-items: center;
        }
        .ant-card-body {
          padding: 0;
          .stage-card {
            border: 1px solid @grey-4;
            padding: 16px;
            margin-bottom: 16px;
          }
        }
        .stage-item-scroll-wrapper {
          overflow-y: auto;
          height: calc(100vh - 200px);
          margin-top: 10px;
          min-height: 400px;
        }
        .stage-item-action-btn {
          bottom: 10px;
          right: 2px;
        }
        &.skeleton-card {
          border: 1px solid @border-color;
          padding: 16px;
        }
      }
      .row-dragging {
        z-index: 10000;
        width: 100%;
        border: none;
        .ant-btn {
          svg {
            fill: rgb(242, 244, 247);
          }
        }
        .stage-card {
          width: 100%;
        }
      }
    }
  }
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner {
  border-color: @primary-color;
}
