.profile {
  &-image {
    object-fit: contain;
    width: 123px;
    height: 123px;
    min-width: 123px;
    min-height: 123px;
  }
  &-header {
    margin-bottom: 15px;
    align-items: center;
    h2 {
      margin-bottom: 0px;
    }
    .ant-btn {
      align-items: center;
      svg {
        fill: @whiteColor;
      }
    }
  }

  &-content {
    &-image {
      position: relative;
      border: 1px solid #e5e8ed;
      border-radius: 4px;
      width: 125px;
      height: 125px;
      margin-right: 20px;
      .action-btns {
        position: absolute;
        top: 0;
        right: 0;
        padding: 3px;
      }
    }
  }
  .ant-descriptions-item-content,
  .ant-descriptions-item-label {
    color: @colorLabel;
  }
  .ant-descriptions-item-label {
    font-weight: 600;
  }
  @media screen and (max-width: 999px) {
    &-wrapper {
      padding: 16px;
    }
    &-header {
      margin-bottom: 0px;
      padding: 16px;
      .page-header {
        padding: 0;
      }
    }
  }
}

.cropper {
  &-bg {
    background-image: url(/assets/images/cropBackground.png);
  }
  &-hidden {
    display: none !important;
  }
}
