.notification {
  .notification-card {
    border: 2px solid @inactive-tag-background;
    border-radius: 16px;
    margin-top: 16px;
    .ant-card-head {
      background: transparent;
      padding: 0 16px;
    }
    .notification-row {
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
    .title {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    .description {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #b3b6c3;
    }
    h4 {
      color: @grey-10;
      margin: 0;
    }
    .notification-header {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: @colorLabel;
    }
    .ant-card-body {
      padding: 16px;
    }
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
    .ant-image {
      .ant-image-img {
        object-fit: contain;
      }
    }
    .comment-time {
      font-size: 12px;
      font-weight: 400;
      color: @colorLabel;
      margin-bottom: 8px;
      line-height: 20px;
    }
    .comment {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 16px;
      line-height: 24px;
    }
  }

  .approver-stage-details {
    &-header {
      svg {
        fill: @dq-colorPrimary;
      }
    }
  }
}
#notification-modal,
#report-config-modal {
  .ant-modal-close-x {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: @dq-colorPrimary;
    }
  }
  .report-config-form {
    .ant-form-item {
      margin-bottom: 4px;
    }
    .ant-divider-horizontal {
      margin: 14px 0;
    }
  }
}
