.users-header-buttons {
  .ant-btn,
  .ant-btn-link {
    &:not(:last-child) {
      margin-right: 5px;
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      text-decoration: none;
      color: white;
      border-color: #ff784f;
      background: #ff784f;
    }

    display: flex;
    align-items: center;
    background: #fc5027;
    border-color: #fc5027;
    color: white;
    svg {
      width: 25px;
      fill: white;
    }
  }
}
.users {
  @media screen and (max-width: 999px) {
    padding: 0 16px 16px;
  }
}

.user-action-btn {
  top: 5px;
  right: 0px;
}
#deactive-user-modal {
  .user-activity-count {
    .title {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #676c77;
      .count {
        color: @text-color;
      }
    }
  }
  .ant-modal-close-x {
    svg {
      fill: @dq-colorPrimary;
    }
  }
  .replace-table-wrapper {
    .ant-select-single.ant-select-open .ant-select-selection-item,
    .ant-select-dropdown .ant-select-item-option-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 120px;
    }
    .ant-table-body {
      overflow-y: auto !important ; //due to inline css
    }
    .ant-table-container {
      border: var(--ant-line-width) var(--ant-line-type)
        var(--ant-table-border-color);
    }
    .ant-table-wrapper .ant-table {
      scrollbar-color: unset;
    }
    .ant-table-body {
      .thin-scrollbar;
    }
    .ant-select .ant-select-selection-placeholder,
    .ant-select-dropdown .ant-select-item-option-content {
      flex: none;
    }
    .ant-select .ant-select-selection-item {
      flex: none;
    }
    .ant-table .ant-table-tbody .anticon {
      font-size: unset;
    }
  }
  .ant-table-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-modal-centered .ant-modal {
    width: 900px !important;
  }
  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: @textColor;
  }
  .ant-table-row {
    background-color: @light-blue;
  }
  .replace-button {
    background-color: @dq-colorPrimary;
    border-radius: 40px;
    color: @whiteColor;
    padding: 5px;
  }
  .background-secondary {
    background-color: @colorLabel;
  }
  .text-secondary {
    color: @colorLabel;
  }
  .background-primary {
    background-color: @dq-colorPrimary;
  }
  .avatar-color {
    margin: 0 5px;
    width: max-content;
  }
  .ant-table .ant-table-tbody .ant-btn {
    height: 30px;
  }
  .replaced-button {
    background-color: @textColor;
  }
  @media screen and (max-width: 999px) {
    .replace-button {
      font-weight: 600;
      color: @dq-colorPrimary;
      padding: 10px;
      background-color: transparent;
      border: none;
    }
    .ant-modal-centered .ant-modal {
      height: 100vh;
      top: 0;
      margin: 0;
      max-width: 100vw;
    }
    .ant-modal-content {
      border-radius: 0;
      height: 100vh;
    }
    .common-scroll-wrapper {
      max-height: calc(100vh - 180px);
      height: auto;
      min-height: auto;
    }
    .replaced-button {
      color: @textColor;
      background-color: transparent;
    }
  }
}
