.report-image-viewer {
  header {
    padding: 8px 16px;
  }
  &-content {
    h2 {
      margin-bottom: 40px;
    }
    .ant-image {
      width: 15%;
      margin: 0 25px 25px 0;
    }
    .ant-card-body {
      .audio-player {
        width: 50%;
      }
      @media screen and (max-width:768px) {
        .audio-player {
          width: 100%;
        }
      }
    }
  }
}
