.project-header {
  margin-bottom: 16px;
  &-title {
    align-items: center;
    h1 {
      margin-bottom: 0px;
    }
    svg {
      fill: @dq-colorPrimary;
      width: 35px;
      height: 35px;
      fill: #fc5027;
      margin-right: 15px;
      margin-bottom: 2px;
    }
  }
  &-button {
    .ant-btn {
      display: flex;
      align-items: center;
      svg {
        width: 25px;
        fill: white;
      }
    }
  }
}

.project-module {
  @media screen and (max-width: 999px) {
    padding: 16px;
  }
  .ant-table-thead > tr > th {
    color: @grey-10;
  }
  .ant-table-tbody > tr > td {
    color: @headerTextColor;
  }
}
.agency-type-radio {
  .ant-radio-wrapper {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: @colorLabel;
  }
  .ant-radio-wrapper.ant-radio-wrapper-checked {
    color: @dq-colorPrimary;
  }
}
.deactivated-color {
  background: @light-blue;
}

.ant-input-number {
  width: 100%;
  .ant-input-number-handler-wrap {
    display: none;
  }
}
.common-map {
  height: 340px;
  width: 100%;
}
.map-row {
  position: relative;
}
.map-input {
  position: absolute;
  border: none;
  z-index: 10;
  height: 40px;
  top: 10px;
  width: 350px;
  left: 10px;
  box-shadow:
    rgb(0 0 0 / 20%) 0px 3px 3px -2px,
    rgb(0 0 0 / 14%) 0px 3px 4px 0px,
    rgb(0 0 0 / 12%) 0px 1px 8px 0px;
}
.project-details {
  .setting-button.ant-btn {
    padding: 4.9px 18px;
    span:first-child {
      margin: 0;
    }
  }
  .nomenclature-info {
    line-height: 1.3;
    max-width: 450px;
    @media screen and (max-width: 555px) {
      width: 300px;
    }
    ol {
      margin: 0;
      padding: 0 0 0 25px;
    }
  }
  &-title {
    &-text {
      align-items: center;
    }
    h1 {
      margin-bottom: 0px;
      font-size: 22px;
    }
    h2 {
      margin-bottom: 0px;
      color: @dq-colorPrimary;
      margin-right: 10px;
    }
    svg {
      min-width: 24px;
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .ant-btn {
      svg {
        margin-right: 0px;
      }
    }
    .edit-button-project {
      align-items: center;
      svg {
        path {
          fill: @whiteColor;
        }
      }
    }
    .edit-button-project-rfi {
      align-items: center;
      span {
        margin-left: 0;
      }
      svg {
        height: 18px;
        width: 18px;
      }
    }
    @media screen and (max-width: 999px) {
      h1 {
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: calc(100vw - 150px);
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }
    @media screen and (min-width: 1000px) {
      margin-bottom: 15px;
    }
  }
  .ant-tabs-tab {
    display: flex;
    min-width: 100px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px 8px 0px 0px;
    justify-content: center;
    background: #e5e8ed;
    &-active {
      background: @whiteColor;
      color: @dq-colorPrimary;
    }
  }
  .ant-tabs-tabpane {
    background: @whiteColor;
    border-radius: 0px 8px 8px 8px;
    padding: 20px 20px;
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin-bottom: 0px;
  }
  .checklists {
    .ant-tag {
      line-height: 22px;
    }
  }

  .checklist-approvers {
    fill: none;
    path {
      fill: @primary-color;
    }
  }
  .approvers-button {
    svg {
      fill: none;
      path {
        fill: @dangerColor;
      }
    }
  }
  .checklist-item-tabs {
    .ant-tabs-tabpane {
      padding: 16px;
      @media screen and (max-width: 999px) {
        padding: 12px;
        .stage-item-card.project-checklist > .ant-card-body {
          padding: 0;
          .infinite-data-container {
            .ant-card-bordered {
              padding: 12px;
            }
          }
        }
      }
    }
  }
  .nomenclature-tab {
    padding: 0;
    border: 1px solid @border-color;
    #add-edit-levels {
      .ant-input-outlined:hover {
        border-color: @tertiaryColor;
        border-right-width: 1px;
        z-index: 1;
      }
      .ant-input-outlined:focus-within {
        border-color: @tertiaryColor;
        box-shadow: 0 0 0 2px rgba(252, 80, 39, 0.2);
      }
      h2 {
        margin: 0;
        line-height: 28px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: @label-color;
        margin-bottom: 10px;
      }
      .form-inputs {
        width: 100%;
        max-height: 450px;
        overflow: auto;
        overflow-x: hidden;
        .text-secondary {
          width: 40px;
          text-wrap: nowrap;
        }
        .remove-col {
          padding: 0 10px 0 0 !important; //due to inline styling of col
        }
        .ant-form-item {
          width: 100%;
        }
      }
    }
    #delete-levels {
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: @label-color;
        margin-bottom: 10px;
      }
      b {
        font-size: 12px;
        margin-bottom: 10px;
        display: block;
      }
      .click-here-button {
        padding: 0;
        height: max-content;
        font-size: 12px;
        font-weight: 500;
        margin-left: 5px;
      }
      ol {
        max-height: 180px;
        overflow: auto;
      }
    }
    #request-access-modal {
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: @label-color;
        margin-bottom: 0;
      }
      .duplicate-icon {
        cursor: pointer;

        g {
          path {
            fill: @primary-color;
          }
        }
      }
      ol {
        padding: 0 0 0 22px;
        .duplicate-icon {
          position: relative;
          top: 5px;
        }
      }
    }
    .disabled-state {
      font-size: 16px;
      // width: calc(100vw - 408px);
      .col-left {
        padding: 20px 0 0 30px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        #create-button {
          z-index: 999;
          .ant-btn-round {
            display: flex;
            align-items: center;
          }
        }
      }
      .main-heading {
        font-size: 38px;
        font-weight: 500;
        line-height: 46px;
        text-align: left;
        margin: 0 0 10px 0;
      }
      .secondary-heading {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin: 0 0 20px 0;
      }
      .col-right {
        text-align: end;
        padding: 0;
        picture {
          position: absolute;
          right: 0;
          z-index: 99;
        }
        iframe {
          box-shadow:
            0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
          position: relative;
          width: 350px;
          height: 280px;
          border-radius: 16px;
          z-index: 999;
          top: 40px;
          right: 40px;
        }
      }
      picture {
        display: block;
        overflow: hidden;
      }
      @media screen and (min-width: 1000px) {
        .ant-card-bordered {
          border: 1px solid #e8e8e8;
          box-shadow: 0px 9px 28px 8px #0000000d;
          .ant-card-body {
            padding: 16px;
          }
        }
      }
      .video-card {
        background-color: #d8d8d8;
      }
      .mobile-nomenclature-image {
        margin-top: -8%;
      }
      @media screen and (max-width: 999px) {
        width: 100%;
        .first-row {
          width: 100%;
          display: flex;
          flex-direction: column-reverse;
        }
        .main-heading {
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          text-align: left;
          margin: 0 0 5px 0;
        }
        .secondary-heading {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
        }
        .col-left {
          padding: 20px 12px 0 12px;
        }
        .col-right {
          picture {
            width: 80%;
            max-width: 300px;
            top: -40%;
          }

          .round-shape {
            position: relative;
            bottom: 0;
            left: 90px;
            width: 100%;
          }

          iframe {
            width: 85%;
            height: 200px;
            top: 15px;
            right: 25px;
          }
        }
      }
      @media screen and (min-width: 500px) and (max-width: 999px) {
        .col-right {
          iframe {
            width: 55%;
            height: 250px;
            top: 15px;
            right: 25px;
          }
        }
      }
    }
  }
  .tag-new.ant-tag {
    border-radius: 2px;
  }
  .nomenclature-info-button {
    path {
      fill: @text-color;
    }
  }
}

.project-tab-details {
  .user-action-dropdown {
    .ant-dropdown-menu .ant-dropdown-menu-title-content {
      flex: none;
    }
  }
  .download-report-pop-confirm {
    width: 250px;
  }
  .filter-search {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 40px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 100%;
    }
    .ant-form-item-control-input-content {
      display: flex;
    }

    .filter-div {
      display: flex;
      align-items: center;
      flex-direction: row;
      border: 1px solid #e5e8ed;
      border-radius: 40px;
      margin: 0px 16px;
      padding: 4px 8px;
      height: 32px;
      .ant-select {
        width: 100%;
      }
    }
  }
  .info-icon {
    path {
      fill: @text-color;
    }
  }
  .info-popover {
    &.ant-popover {
      max-width: 300px;
      .ant-popover-inner-content {
        width: auto;
        max-width: unset;
      }
      ul {
        padding-left: 20px;
        margin: 0;
      }
    }
  }
  .arrow-button {
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: @text-color;
      border-color: @text-color;
    }
    padding: 5px 0;
    background-color: @text-color;
    border-color: @text-color;
    &.ant-btn[disabled] {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      svg {
        path {
          fill: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
  .plan-vs-actual-layout {
    border: none;
    .main-layout {
      .ant-ribbon {
        top: 4px;
      }
    }
    .ant-card {
      background: @border-color;
      margin-bottom: 10px;
    }
    .heading-week-div {
      border-radius: 40px;
      box-shadow:
        0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      padding: 10px 30px;
      text-align: center;
      background-color: @text-color;
      color: @white-color;
      div:not(:nth-child(2)) {
        width: 75px;
      }
      div:nth-child(2) {
        margin: 0 10px;
        @media screen and (max-width: 550px) {
          margin: 0 5px;
        }
      }
    }
    .statistic-card {
      width: 100%;
      background-color: @white-color;
      margin-bottom: 0;
      box-shadow:
        0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      .ant-card-body {
        .ant-row {
          width: 100%;
        }
      }
      .achieved-stats {
        margin: 2px;
      }
      .ant-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          color: #89889f;
          font-weight: 500;
        }
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }
        .ant-input {
          width: 65px;
          font-size: 18px;
          padding: 0;
          text-align: center;
          &::placeholder {
            font-size: 16px;
          }
          &:hover {
            background: #fff;
          }
        }
        .ant-input:focus,
        .ant-input-focused {
          box-shadow: none;
        }
      }
      .achieved-col {
        .ant-popover-inner {
          width: 300px;
        }
        &:hover {
          .download-report-button {
            display: block;
          }
        }
      }
      .download-report-button {
        display: none;
        position: absolute;
        top: 25px;
        width: auto;
        &.ant-btn-round {
          padding: 6px 18px;
        }
        svg {
          fill: @white-color;
        }
      }
    }
    .ant-divider-vertical {
      height: 1.5em;
      border-left: 2px solid rgba(0, 0, 0, 0.06);
    }
    .common-scroll-wrapper {
      height: fit-content;
      min-height: fit-content;
      width: 100%;
      .infinite-loader {
        display: none;
      }
    }
  }
  .plan-vs-actual-layout {
    .info-icon {
      path {
        fill: @text-color;
      }
    }
  }
  .current-week-button {
    margin-right: 42px;
  }
  .plan-vs-actual-card {
    width: calc(33.33% - 10px);
    margin: 5px 5px;
    .ant-card-body {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    @media screen and (max-width: 900px) {
      width: calc(50% - 10px);
      .heading-week-div {
        div {
          width: auto;
        }
      }
    }
    @media screen and (max-width: 550px) {
      width: calc(100% - 10px);
    }
    .current-week-tag {
      color: @primary-color;
      top: 4px;
      position: absolute;
      right: 0;
      left: 0;
      margin: auto;
      width: 90px;
    }
    .ant-card-body {
      padding: 16px;
    }
  }
  .ant-btn.ant-btn-round.ant-btn-primary.assign-action-button {
    padding: 4px 12px;
    margin: 0;
    @media screen and (max-width: 999px) {
      height: 28px;
    }
  }
  &.nomenclature-module {
    .view-only-tag {
      border-radius: 2px;
      color: @white-color;
      background-color: @info-color;
      display: flex;
      align-items: center;
      height: 20px;
    }
    .ant-segmented-item-selected {
      color: @white-color;
      background-color: @text-color;
    }
    .ant-segmented-thumb {
      background-color: @text-color;
    }

    .top-row {
      border-bottom: 1px solid @border-color;
      .main-action-buttons {
        padding: 5px 5px;
        .export-button {
          border: 1px solid #e5e8ed;
          &:hover {
            svg {
              fill: @primary-color;
            }
          }
          svg {
            fill: @grey-10;
          }
        }
        .ant-btn-round {
          display: flex;
          align-items: center;
        }
        .live-tag {
          border-radius: 2px;
          color: @white-color;
          background-color: #006600;
          height: 20px;
        }
      }
    }
    .tree-col {
      max-width: 100%;
      width: 100%;
      display: flex;
      border-right: 1px solid @border-color;
      .search-div {
        padding: 5px 10px;
        border-bottom: 1px solid @border-color;
        .search-component {
          height: 32px;
        }
      }
      .blur-tree-icon {
        width: 356px;
      }
    }
    .nomenclature-mobile {
      .header {
        padding: 5px;
        border-bottom: 1px solid @border-color;
        .export-button {
          border: 1px solid @border-color;
        }
        .ant-btn-round {
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .detail {
        height: calc(100vh - 288px);
        overflow: auto;
        .tree-structure {
          max-width: unset;
          width: unset;
          height: auto;
        }
      }
    }
    .chart-structure {
      width: 100%;
      .react-flow {
        height: calc(100vh - 335px) !important; // due to inline css
      }
      .main-action-buttons {
        padding: 5px 5px;
        border-bottom: 1px solid #e5e8ed;
        .export-button {
          border: 1px solid #e5e8ed;
          &:hover {
            svg {
              fill: @primary-color;
            }
          }
          svg {
            fill: @grey-10;
          }
        }
        .ant-btn-round {
          display: flex;
          align-items: center;
        }
        .live-tag {
          border-radius: 2px;
          color: @white-color;
          background-color: #006600;
          height: 20px;
        }
      }
    }
  }
}
#assign-agency-modal {
  .ant-steps {
    width: 75%;
  }
  .ant-modal-close-x {
    svg {
      fill: @dq-colorPrimary;
    }
  }
  .no-tag-render {
    .ant-select-selection-overflow-item .ant-select-selection-item {
      display: none;
    }
  }
}

#pause-remark-modal {
  .ant-modal-close-x {
    svg {
      fill: @dq-colorPrimary;
    }
  }
  .ant-modal-footer {
    border: none;
    padding: 16px 24px 24px;
    text-align: start;
  }
  .ant-modal-body {
    padding: 24px 24px 0;
  }
  .common-scroll-wrapper {
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

#add-edit-agency-modal {
  .ant-steps {
    width: 75%;
  }
  .ant-divider-dashed {
    border-color: #cdd5e0;
    border-width: 2px 0 0;
  }
  .ant-divider-horizontal {
    margin: 24px 0;
  }
  .create-agency-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: @colorLabel;
  }
  .add-agency-card {
    border: 2px solid @inactive-tag-background;
    border-radius: 8px;
    margin-top: 16px;
    .ant-card-body {
      padding: 10px;
    }
    .ant-form-item:last-child {
      margin-bottom: 0;
    }
  }
  .user-card {
    border: 2px solid @inactive-tag-background;
    border-radius: 8px;
    .ant-card-body {
      padding: 0 10px;
    }
    .ant-btn-round {
      padding: 4px 0;
    }
    .ant-form-item:last-child {
      margin-bottom: 0;
    }
    .height-0 {
      height: 0;
    }
  }
  .ant-btn-link {
    padding: 0;
  }
  @media screen and (max-width: 999px) {
    .ant-card-bordered:not(:last-of-type) {
      margin-bottom: 5px;
    }
  }
  .ant-modal-close-x {
    svg {
      fill: @dq-colorPrimary;
    }
  }
  .no-tag-render {
    .ant-select-selection-overflow-item .ant-select-selection-item {
      display: none;
    }
  }
}
.project-user-modal {
  .rfi-note {
    margin-top: -15px;
  }
  h2 {
    font-weight: 500;
  }
  .ant-row.ant-form-item {
    margin-bottom: 15px;
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    color: @grey-10;
  }
}

.project-tab-details {
  &-header {
    margin-bottom: 15px;
    h2 {
      margin-bottom: 0px;
    }
  }
  .approver-info-icon {
    transform: rotate(180deg);
  }
}
.instruction-tab-details {
  &-summary {
    .ant-table-wrapper {
      max-width: 750px;
    }
  }
  .report-button {
    background: @inactive-tag-background;
    &.ant-btn {
      padding-top: 7.9px !important;
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      text-decoration: none;
      color: #ff784f;
      border-color: #ff784f;
      background: #e5e8ed;
    }
    svg {
      fill: @grey-10;
    }
    &:hover:not([disabled]) {
      svg {
        fill: @dq-colorPrimary;
      }
    }
    &.ant-btn[disabled] {
      svg {
        fill: rgba(0, 0, 0, 0.25);
      }
      &:hover svg {
        fill: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .ant-btn {
    margin-right: 5px;
    display: flex;
    align-items: center;
    svg {
      width: 26px;
    }
    span {
      margin-left: 5px;
    }
  }
  .ant-table-content {
    border: 0px;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: 0px;
  }

  tbody.ant-table-tbody {
    color: @headerTextColor;
  }
  &-recommendation {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 300px;
      border-radius: 40px;
    }
    .ant-select-arrow {
      width: 18px;
    }
    td.ant-table-cell {
      max-width: 500px;
      img.image {
        margin-right: 5px;
        flex-shrink: 0;
        cursor: pointer;
        margin-bottom: 5px;
      }
      .ant-image {
        margin-right: 5px;
      }
    }
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 5px 5px;
  }
}

.register-module {
  .ant-segmented {
    .ant-segmented-group {
      .ant-segmented-item-selected {
        background-color: #202a3f;
        color: @white-color;
      }
    }
  }
}

.register-details {
  .ant-tabs-editable {
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            padding: 0;
          }
        }
      }
    }
  }
  .coming-soon-icon {
    font-size: 48px;
  }
}
.ant-table-wrapper .ant-table-thead > tr > th {
  z-index: 9 !important; //due to inline css
}

.image-none {
  display: none;
}

.ant-image .ant-image-mask:hover {
  opacity: 0;
}

.eqc-tab-details,
.eqc-type-details,
.project-details {
  .report-button {
    border: none;
    background-color: @inactive-tag-background;
    &.ant-btn {
      padding-top: 7.9px !important;
      @media screen and (max-width: 999px) {
        padding-top: 4.9px !important;
      }
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      border: none;
      background: #e5e8ed;
      color: #ff784f;
    }
    svg {
      fill: @grey-10;
    }
    &:hover:not([disabled]) {
      svg {
        fill: @dq-colorPrimary;
      }
    }
    &.ant-btn[disabled] {
      svg {
        fill: rgba(0, 0, 0, 0.25);
      }
      &:hover svg {
        fill: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .comment-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled) {
    background: @textColor;
    color: @grey-1;
    border: none;
    svg {
      fill: @grey-1;
      &:hover {
        fill: @dq-colorPrimary;
      }
    }
  }
  .ant-btn {
    margin-right: 5px;
    display: flex;
    align-items: center;
  }
  .checklist-details {
    &.ant-card {
      border-radius: 12px;
    }
    .edit-button-checklist {
      align-items: center;
      svg {
        fill: @whiteColor;
      }
    }
    .unarchive-button-checklist {
      align-items: center;
      span {
        margin: 0 5px 0 0;
      }
      svg {
        height: 18px;
        width: 18px;
        margin-top: 2px;
        fill: @whiteColor;
      }
    }
    .delete-button-checklist {
      border: 1px solid #d9d9d9;
      align-items: center;
      &:hover {
        svg {
          path {
            fill: @primary-color;
          }
        }
      }
      svg {
        fill: none;
        path {
          fill: @text-color;
        }
      }
      span {
        margin: 0;
      }
    }
    .change-log-button {
      text-decoration: underline;
      padding: 0;
      color: #676c77;
      cursor: pointer;
      line-height: 22px;
      font-weight: 500;
      margin-top: 5px;
      width: fit-content;
    }
    .checklist-basic-details {
      @media screen and (max-width: 555px) {
        flex-direction: column;
        .checklist-details-form .ant-form-item {
          width: 100%;
          margin: 0 0 20px;
          .ant-row.ant-form-item-row {
            width: 100%;
          }
        }
      }
    }
    .checklist-details-form {
      max-width: calc(100wh - 400px);
      flex-wrap: wrap;
      @media screen and (max-width: 555px) {
        flex-wrap: wrap;
      }

      .ant-form-item {
        margin-right: 20px;
      }
      .ant-row.ant-form-item-row {
        width: 200px;
      }
    }
  }
  .approvers-setting-tab.ant-tabs-tabpane {
    padding: 0;
  }
  .checklist-setting-tab {
    .nested-form {
      margin: 0;
      .ant-select-dropdown .ant-select-item-option {
        padding: 5px 8px;
      }
    }
    .common-dropdown-wrapper
      .ant-select.ant-select-single
      .ant-select-selector {
      border-radius: 8px;
      border: 1px solid #e5e8ed;
      background: #fff;
    }
  }
  .change-log-module {
    .ant-descriptions-item-label {
      color: #676c77;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
    .ant-descriptions-item-content {
      color: #202a3f;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    .version-log-card {
      border-radius: 16px;
      border: 2px solid #e5e8ed;
      margin-top: 16px;
      ol {
        padding-left: 32px;
      }
      .ant-card-body {
        padding: 16px;
      }
      .title {
        color: #202a3f;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        .info-text {
          color: #b3b6c3;
          font-weight: 400;
        }
      }
    }
  }
}
.comment-modal-body .comment-img-wrapper img {
  max-width: 400px;
  max-height: 400px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.eqc-stage-details {
  .ap-info {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: @dq-colorPrimary;
    margin-top: 12px;
  }
  .no-remark {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .has-remark {
    cursor: pointer;
    @media screen and (max-width: 999px) {
      fill: @colorLabel;
    }
  }
  .approver-stage-details {
    .ant-card-body {
      padding: 0;
    }
    .comment-card {
      .ant-card-body {
        padding: 16px;
      }
      .remark-title {
        color: #676c77;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }
  p {
    margin: 0 0 5px 0;
  }
}
.auditor-comment-modal {
  min-height: 300px;
  .auditor-view-comment-wrap {
    display: flex;
    margin: 0.2rem 0 1rem 0;
    flex-direction: column;
    border-bottom: 1px solid #ddd;
    p {
      margin: 0.125rem;
      word-break: break-word;
      span {
        align-self: flex-end;
        float: right;
        text-align: center;
        font-style: italic;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}
.project-action-btn {
  top: 5px;
  right: 0px;
  .ant-btn-round.ant-btn-icon-only {
    padding: 0;
    margin-right: 5px;
  }
}
@media screen and( max-width: 999px) {
  img.image {
    border-radius: 4px;
  }
  .project-tab-details-header h2 {
    margin-bottom: 0.5em;
  }
  .project-tab-details .filter-search {
    .filter-div {
      margin: 0px 8px 0 0;
      width: 100%;
      margin-bottom: 8px;
    }
  }
  .eqc-tab-details-summary {
    &.ant-card-bordered {
      border: none;
    }
  }
  .instruction-tab-details {
    .ant-card-bordered {
      border: none;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
    }
  }
  .project-module {
    &.ant-card-bordered {
      border: none;
      border-radius: 0;
    }
  }
  .ant-steps {
    overflow: auto;
    .ant-steps-item {
      overflow: unset;
      .ant-steps-item-title::after {
        width: 48px;
      }
    }
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item:not(:first-of-type) {
    margin-left: 48px;
  }
}
.recommendation-collapse {
  background: transparent;
  .ant-collapse-header {
    width: fit-content;
  }
  .ant-collapse-item {
    border: none;
  }
  & > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    margin-bottom: 5px;
  }
  .panel-header {
    font-weight: 500;
    width: fit-content;
    color: @colorLabel;
    padding: 8px 16px 8px 16px;
    border-radius: 40px;
    background: @backgroundColor;
  }
  & .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .previous-details-card-wrapper {
    padding: 16px;
    border: 1px solid @border-color;
    border-radius: 16px;
  }
}

.approver-info {
  .ant-popover-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: @textColor;
    padding: 16px 16px 0;
    border: none;
    margin-bottom: 0;
    .expand-text.ant-typography {
      font-size: 20px;
    }
  }
  .approvers-card {
    border: none;

    .ant-card-body {
      width: 100%;
      padding: 0;
    }
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-content {
    width: 350px;
  }
  .ant-popover-inner {
    border-radius: 12px;
    padding: 0;
  }
  .ant-popover-inner-content {
    padding: 16px;
    max-height: calc(100vh - 300px);
    overflow: auto;
    .approver-divider {
      margin: 12px 0;
    }
  }
  @media screen and(max-width:500px) {
    padding: 0 5px;
    .ant-popover-content {
      width: 310px;
    }
  }
}
.register-attachment {
  &.ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-container,
  &.ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select {
    height: 80px;
    width: 80px;
  }
  .ant-upload-list-item-actions {
    display: flex;
    justify-content: center;
  }
  &.ant-upload-wrapper
    .ant-upload-list
    .ant-upload-list-item
    .ant-upload-list-item-actions
    .ant-upload-list-item-action {
    margin: 0;
  }
  &.ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-file
    + .ant-upload-list-item-name {
    display: none;
  }
}
.ant-table {
  .form-field-select {
    .ant-select-clear .anticon {
      font-size: 15px;
    }
  }
}
