.dashboard-wrapper {
  h3 {
    margin-bottom: 0;
  }
  .ant-collapse {
    border: none;
    background: transparent;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
    margin-bottom: 16px;
    background: white;
  }

  .ant-collapse-content {
    border: none;
  }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .common-card-wrapper {
    padding-top: 0;
  }
}
li.recharts-legend-item {
  margin: 5px;
}
.custom-date-picker {
  background: @whiteColor;
  border-radius: 40px;
  box-shadow: none;
  width: 245px;
  height: 32px;
  .ant-picker-active-bar {
    background: none;
  }
  svg {
    width: 22px;
    fill: @dq-colorPrimary;
    height: 22px;
    margin-right: 0px;
  }
  input {
    line-height: 1;
  }
}
@media screen and (max-width: 999px) {
  .custom-date-picker-panel {
    .ant-picker-panel {
      &:last-child {
        width: 0;
        .ant-picker-header {
          position: absolute;
          right: 0;
          .ant-picker-header-prev-btn,
          .ant-picker-header-view {
            visibility: hidden;
          }
        }
        .ant-picker-body {
          display: none;
        }
      }
    }
    .ant-picker-footer-extra {
      line-height: 18px;
      padding: 8px 12px;
    }
  }
}
