.register-tabs {
  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          max-width: 250px;
        }
      }
    }
  }
}

.register-table-modal {
  .ant-divider-dashed {
    border-color: #cdd5e0;
    border-width: 2px 0 0;
  }
  .ant-divider-horizontal {
    margin: 15px 0;
  }
  .value-input {
    margin: 10px 5px;
    height: 30px;
    padding: 10px;
    .ant-form-item-control-input {
      width: 131px;
    }
  }
  h2 {
    margin: 0;
  }
  .note {
    color: @label-color;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-decoration-line: underline;
    width: fit-content;
    padding: 12px 0 16px;
    margin-right: 5px;
  }
}

.register-container {
  height: 100%;
  width: 100%;
  display: flex;
  .add-register-card-wrapper {
    flex: 1;
    background-color: red;
  }
}
