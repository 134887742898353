.reports {
  &-header {
    margin-bottom: 25px;
    h1 {
      margin-bottom: 0px;
    }
    svg {
      width: 35px;
      fill: @dq-colorPrimary;
      height: 35px;
      margin-right: 16px;
    }
  }
  &-filters {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 40px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 32px;
    }
    .ant-form-item-control-input-content {
      display: flex;
    }
    .ant-select-selector {
      display: flex;
      align-items: center;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      box-shadow: none;
    }
    .filter-div {
      display: flex;
      align-items: center;
      flex-direction: row;
      border-radius: 40px;
      height: 32px;
      margin: 0 4px;
      min-width: 200px;
      .ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow {
        width: 100%;
      }
      .icon {
        height: 22px;
        width: 22px;
        fill: @dq-colorPrimary;
      }
    }
    .disabled {
      color: #cfd2d7;
      display: flex;
      align-items: center;
      flex-direction: row;
      border-radius: 40px;
      height: 32px;
      margin: 0 4px;
      min-width: 200px;
      svg {
        path {
          fill: #cfd2d7;
        }
      }

      .ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow {
        width: 100%;
      }
      .icon {
        height: 22px;
        width: 22px;
        path {
          fill: #cfd2d7;
        }
      }
    }
    .ant-divider-vertical {
      border: 1px solid @inactive-tag-background;
      height: 20px;
    }
    .ant-btn,
    .ant-btn-link {
      display: flex;
      align-items: center;
      color: #fff;
      background: #fc5027;
      border-color: #fc5027;
      svg {
        width: 25px;
        fill: @whiteColor;
      }
    }
    .ant-btn-round {
      height: 34px;
    }
  }
  &-content {
    margin-bottom: 24px;
  }
  .project-report {
    background: @whiteColor;
    h3 {
      margin-bottom: 0px;
    }
  }

  .individual-reports {
    .filter-div {
      width: 100%;
      margin: 0px;
    }
    .ant-btn,
    .ant-btn-link {
      margin-right: 5px;
      display: flex;
      align-items: center;
      color: #fff;
      background: #fc5027;
      border-color: #fc5027;
      svg {
        width: 25px;
        fill: @whiteColor;
      }
    }
    h3 {
      margin-bottom: 16px;
    }
    .icon {
      fill: @dq-colorPrimary;
    }
  }
  a.ant-btn {
    // As antd button type link by default is using  important in paddingtop
    padding-top: 8px !important;
  }
  .custom-date-picker {
    background: @whiteColor;
    border-radius: 40px;
    box-shadow: none;
    width: 245px;
    .ant-picker-active-bar {
      background: none;
    }
    svg {
      width: 25px;
      fill: @dq-colorPrimary;
      height: 25px;
      margin-right: 0px;
    }
    input {
      line-height: 1;
    }
  }
  .blinking-border {
    border: 2px solid @dq-colorPrimary;
    animation-name: blinking;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  @keyframes blinking {
    50% {
      border-color: @whiteColor;
    }
  }
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: @grey-10;
  background: @whiteColor;
  border-color: @inactive-tag-background;
}
.report-dropdown-menu {
  & > li.ant-dropdown-menu-item {
    padding: 0 20px;
    height: 42px;
    max-height: 42px;
    font-size: 16px;
    color: @grey-10;
    &:hover {
      color: @dq-colorPrimary;
      background-color: transparent;
    }
  }
}
.reports-note {
  list-style: decimal;
  font-size: 12px;
}
@media screen and (max-width: 999px) {
  .reports .ant-card-bordered {
    border: none;
    border-radius: 0;
  }
  .reports-filters .disabled,
  .reports-filters .filter-div,
  .reports .individual-reports .ant-btn,
  .reports .individual-reports .ant-btn-link {
    margin: 0;
  }
  // .custom-date-picker-panel {
  //   .ant-picker-panel {
  //     &:last-child {
  //       width: 0;
  //       .ant-picker-header {
  //         position: absolute;
  //         right: 0;
  //         .ant-picker-header-prev-btn,
  //         .ant-picker-header-view {
  //           visibility: hidden;
  //         }
  //       }
  //       .ant-picker-body {
  //         display: none;
  //       }
  //     }
  //   }
  //   .ant-picker-footer-extra {
  //     line-height: 18px;
  //     padding: 8px 12px;
  //   }
  // }
}
