.logs {
  .logs-filter {
    max-width: calc(100vw - 30%);
  }
  @media screen and (max-width: @screen-lg-max) {
    padding: 0 16px 16px;
  }
  .ant-tabs-ink-bar {
    background: @dq-colorPrimary;
  }
  h1 {
    width: max-content;
  }
  .ant-tabs-tabpane {
    padding: 20px 20px;
    @media screen and (max-width: @screen-lg-max) {
      height: max-content;
      padding: 12px;
      background: white;
    }
    .ant-descriptions-item-label {
      color: @grey-10;
    }
    .ant-descriptions-item-content {
      color: @headerTextColor;
    }
    .details-owner-description {
      margin-bottom: 25px;
    }
  }
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    margin-bottom: 0px;
  }
  .ant-tabs-tab {
    width: 140px;
    display: flex;
    border-radius: 8px 8px 0px 0px;
    justify-content: center;
    background: #e5e8ed;

    &-active {
      background: @whiteColor;
      color: @dq-colorPrimary;
      .ant-tabs-tab-btn {
        color: @dq-colorPrimary;
      }
    }
    &:hover {
      color: @dq-colorPrimary;
    }
    .ant-tabs-top > .ant-tabs-nav,
    .ant-tabs-bottom > .ant-tabs-nav,
    .ant-tabs-top > div > .ant-tabs-nav,
    .ant-tabs-bottom > div > .ant-tabs-nav {
      margin-bottom: 0px;
    }
  }

  & .ant-tabs-tabpane {
    background: @whiteColor;
    border-radius: 0px 8px 8px 8px;
  }
  .tag-beta.ant-tag {
    border-radius: 2px;
  }
}

.ant-table-thead > tr > th {
  background: @whiteColor;
}

.ant-table-content {
  border: 1px solid #e5e8ed;
  border-radius: 8px;
}

.instruction-logs-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.instruction-selector {
  .ant-select-selector {
    display: flex;
    align-items: center;
  }
}

.activity-scroll-wrapper,
.instruction-scroll-wrapper {
  overflow-y: auto;
  height: calc(100vh - 200px);
  min-height: 400px;
  overflow-x: hidden;
  &.image-list {
    .infinite-data-container {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -8px;
      width: auto;
    }
  }
}

.gallery-image {
  .ant-image .ant-image-mask:hover {
    opacity: 0;
  }
  width: calc(20% - 16px);
  margin: 0 8px;
  position: relative;
  &:hover {
    .info-button {
      opacity: 1;
    }
  }
  @media screen and (max-width: 575px) {
    width: calc(40% - 16px);
  }
  @media screen and (min-width: 1440px) {
    width: calc(20% - 130px);
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-content {
    width: 310px;
    height: auto;
    .ant-popover-inner {
      background: @light-blue;
      .ant-descriptions-item-label,
      .ant-descriptions-item-content {
        font-size: 16px;
      }
    }
  }
  .ant-image {
    margin: 0 0 16px 0;
    width: 100%;
    .ant-image-img {
      border-radius: 8px;
      object-fit: cover;
    }
  }
  .ant-btn-icon-only {
    height: 24px;
    width: 24px;
    padding: 3px 0;
  }
  .info-button {
    position: absolute;
    right: 10px;
    top: 10px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.35s ease;
    @media screen and (max-width: 999px) {
      opacity: 1;
    }
  }
}
